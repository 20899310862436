import { FC, ReactNode } from 'react';

import { Icon, IconTypes } from '@vakantiesnl/components/src/atoms/Icon';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';

import { useCustomStyles } from './Link.style';

export type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> &
	Omit<NextLinkProps, 'href' | 'onClick'> & {
		children: ReactNode;
		icon?: IconTypes;
		size?: 'small' | 'medium' | 'large';
		className?: string;
	} & (
		| {
				onClick: NextLinkProps['onClick'];
				href?: never;
		  }
		| {
				onClick?: NextLinkProps['onClick'];
				href: NextLinkProps['href'];
		  }
	);

const Link: FC<LinkProps> = ({ children, icon, size = 'small', className, onClick, href, ...rest }) => {
	const { classes, cx } = useCustomStyles({ size });

	return (
		<NextLink {...rest} href={href ?? '#0'} className={cx(classes.defaultLink, className)} onClick={onClick}>
			<>
				{icon && <Icon type={icon} size={'medium'} className={classes.icon} />}
				{children}
			</>
		</NextLink>
	);
};

export { Link };
